<template>
    <v-card class="teros-elevation mt-2" :color="tableColor">
        <v-card-title class="px-5  mb-2  pb-0 v-title-cadastro">
            <span class="h5 font-weight-bold">Projeto: <span class="">{{ projeto.titulo }}</span></span>
        </v-card-title>
        <MasterDetail
            :cols="cols"
            :opts="opts"
            :hasNewButton="false"
            :canDelete="false"
            :canEdit="false"
            :hasFilter="true"
            :hasExportCSV="false"
            :hasExportXLS="false"
            :customResource="customResource"
            :lastRowData="lastRowData"
        >
            <v-row>
                <v-select
                    class="selectParcelas mr-3"
                    offset-y
                    v-model="numero_parcela"
                    :items="parcelas"
                    item-text="numero_parcela"
                    item-value="numero_parcela"
                    dense
                    outlined
                >
                <template v-slot:label>
                    <span style="font-size: 12px"> Selecione a parcela </span>
                </template>
                <template v-slot:item="{ item }">
                    <span> RTA - {{ item.numero_parcela }}</span>
                </template>
                <template v-slot:selection="{ item }">
                    <span> RTA - {{ item.numero_parcela }}</span>
                </template>
                </v-select>
                <DropdownCaptacao></DropdownCaptacao>
            </v-row>
        </MasterDetail>
    </v-card>
</template>

<script>
import { mapGetters } from 'vuex';

export default {
    // props: {},
    components: {
            MasterDetail: () => import('@/components/master-detail.vue'),
            DropdownCaptacao: () => import('@/components/dropdown-captacao.vue'),
        },
    // mixins: [],
    // directives: {},
    computed: {
        ...mapGetters(['clientId']),
        projetoId() {
            return this.$route.query.projetoId;
        },
        cols() {
            return [
                {
                    key: 'rubrica',
                    name: 'Rubrica',
                    type: this.$fieldTypes.TEXT,
                },
                {
                    key: 'valor_previsto',
                    name: 'Orçamento aprovado',
                    type: this.$fieldTypes.MONEY,
                },
                {
                    key: 'valor_anterior',
                    name: 'Período anterior',
                    type: this.$fieldTypes.MONEY,
                },
                {
                    key: 'valor_realizado',
                    name: 'Realizado no período',
                    type: this.$fieldTypes.MONEY,
                },
                {
                    key: 'valor_contrapartida',
                    name: 'Contrapartida no período',
                    type: this.$fieldTypes.MONEY,
                },
                {
                    key: 'total_realizado',
                    name: 'Total realizado',
                    type: this.$fieldTypes.MONEY,
                },
                {
                    key: 'saldo_disponivel',
                    name: 'Saldo disponível',
                    type: this.$fieldTypes.MONEY,
                },

                // {
                //     key: 'descricao',
                //     name: 'Descrição',
                //     type: this.$fieldTypes.TEXT,
                //     truncate: this.visibilidade.VL_DESCRICAO ? false : true,
                //     wrap: this.visibilidade.VL_DESCRICAO ? true : false,
                //     icon: {
                //         text: `mdi-unfold-${
                //             this.visibilidade.VL_DESCRICAO ? "less" : "more"
                //         }-vertical`,
                //         style: `
                //             background-color: ${ this.isClient ? "#6BB4BA" : "#81B4EA" };
                //             border-radius: 50%;
                //             color: #000;
                //             padding: 2px;
                //             margin-left: 2px;`,
                //         click: () => {
                //             this.visibilidade.VL_DESCRICAO = !this.visibilidade.VL_DESCRICAO;
                //         },
                //     },
                //     width: this.visibilidade.VL_DESCRICAO ? '350px' : '10px',
                //     colSize: 4,
                // },
                // {
                //     key: 'finalidade',
                //     name: 'Finalidade',
                //     type: this.$fieldTypes.TEXT,
                //     highlighted: true,
                //     truncate: this.visibilidade.VL_DESCRICAO ? false : true,
                //     wrap: this.visibilidade.VL_DESCRICAO ? true : false,
                //     icon: {
                //         text: `mdi-unfold-${
                //             this.visibilidade.VL_FINALIDADE ? "less" : "more"
                //         }-vertical`,
                //         style: `
                //             background-color: ${ this.isClient ? "#6BB4BA" : "#81B4EA" };
                //             border-radius: 50%;
                //             color: #000;
                //             padding: 2px;
                //             margin-left: 2px;`,
                //         click: () => {
                //             this.visibilidade.VL_FINALIDADE = !this.visibilidade.VL_FINALIDADE;
                //         },
                //     },
                //     width: this.visibilidade.VL_FINALIDADE ? '350px' : '10px',
                //     colSize: 4,
                // },
            ]
        },
        resourceOrcamento() {
            return this.apiResource(`v1/captacao/${this.clientId}/orcamentos/${this.projetoId}/detalhado`);
        },
        resourceUrl() {
            return this.apiResource(`v1/captacao/${this.clientId}/orcamentos/${this.projetoId}/comprovacao`.concat(this.numero_parcela ? `?numero_parcela=${this.numero_parcela}` : `?numero_parcela=1`));
        },
        customResource() {
            const resource = this.resourceUrl;
            const that = this;
            return {
                ...resource,
                get() {
                    return resource.get().then((result) => {
                        that.lastRowData = result.reduce(
                            (acc, curr) => ({
                            ...acc,
                            valor_previsto: Number.parseFloat(acc.valor_previsto) + Number.parseFloat(curr.valor_previsto),
                            valor_anterior: acc.valor_anterior + curr.valor_anterior,
                            valor_realizado: acc.valor_realizado + curr.valor_realizado,
                            valor_contrapartida: acc.valor_contrapartida + curr.valor_contrapartida,
                            total_realizado: acc.total_realizado + curr.total_realizado,
                            saldo_disponivel: acc.saldo_disponivel + curr.saldo_disponivel,
                        }),
                        {
                            nome: "Total",
                            valor_previsto: 0,
                            valor_anterior: 0,
                            valor_realizado: 0,
                            valor_contrapartida: 0,
                            total_realizado: 0,
                            saldo_disponivel: 0,
                        })
                        return result;
                    });
                }
            }
        }
    },
    data() {
        return {
            visibilidade: {
                VL_FINALIDADE: false,
                VL_DESCRICAO: false,
            },
            opts: {
                rubricas:[],
                projetos: [],
                descricoes: [],
                parcelas: [],
            },
            projeto: {
              titulo: '',
            },
            numero_parcela: null,
            parcelas: [],
            lastRowData: {}
        }
    },
    created() {
        this.getProjetoSelecionado();
        this.getOrcamento();
    },
    // mounted() {},
    // updated() {},
    // destroyed() {},
    methods: {
        getProjetoSelecionado(){
            return this.apiResource(`/v1/captacao/projetos/${this.clientId}/${this.projetoId}`).get().then((result) => {
                return this.projeto = result;
            });
        },
        getOrcamento() {
            const resource = this.resourceOrcamento;
            const that = this;
            resource.get().then((result) => {
                that.parcelas = result.parcelas;
            });
        },
    },
        // watch: {},
    }
</script>

<style>
</style>